<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="800px"
      :ok-button-props="{ props: { loading: submitting } }"
      title="货品明细"
    >
      <a-table
        :columns="columns"
        :data-source="goodsList"
        :loading="loading"
        :pagination="false"
      >
        <span slot="elegiac_information" slot-scope="text, record">
          <a-space v-if="record.elegiac_information && record.elegiac_information.length > 0">
            <a @click="showElegiacInformationModal(record)">点击查看</a>
          </a-space>
          <a-space v-else>
            -
          </a-space>
        </span>
      </a-table>
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>

      <show-elegiac-information
        v-if="isShowElegiac"
        :visible.sync="isShowElegiac"
        :elegiac-information="elegiacInformation"
      />
    </a-modal>
  </div>
</template>

<script>

export default {
  name: 'GoodsList',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    goodsList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  components: { ShowElegiacInformation: () => import('@/views/orders/system/ElegiacInformation') },
  data() {
    return {
      submitting: false,
      loading: false,
      previewVisible: false,
      previewImages: [],
      elegiacInformation: [],
      isShowElegiac: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '序号',
          width: 90,
          dataIndex: 'sort_num'
        },
        {
          title: '品名',
          width: 90,
          dataIndex: 'goods_name'
        },
        {
          title: '型号',
          width: 90,
          dataIndex: 'goods_model'
        },
        {
          title: '类型',
          width: 90,
          dataIndex: 'goods_type'
        },
        {
          title: '规格',
          width: 90,
          dataIndex: 'goods_spec_name'
        },
        {
          title: '商品数量',
          width: 90,
          dataIndex: 'goods_quantity'
        },
        {
          title: '挽联信息',
          width: 90,
          dataIndex: 'elegiac_information',
          scopedSlots: { customRender: 'elegiac_information' }
        }
      ]
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    },
    showElegiacInformationModal(record) {
      this.elegiacInformation = record.elegiac_information
      this.isShowElegiac = true
    }
  }
}
</script>

<style scoped>

</style>
